import { Component, Input } from '@angular/core';
import { Message, MessageType } from 'src/app/shared/model/message';

@Component({
  selector: 'message-panel',
  templateUrl: './message-panel.component.html',
  styleUrls: ['./message-panel.component.scss']
})
export class MessagePanelComponent {

  @Input() messages!: Message[];

  constructor() { }

  messageAction(message: Message): void {
    message.action && message.action();
  }
}
