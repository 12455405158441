<app-header *ngIf="!customHeader else customheadertemplate"></app-header>
<ng-template #customheadertemplate>
  <div
    #customHeader
    id="customHeader"
    domChange
    (dom-changed)="setHeaderMargin()"
  ></div>
</ng-template>

<div #appWrapper class="app-wrapper">
  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>
  <app-toast></app-toast>
  <app-footer></app-footer>
</div>
