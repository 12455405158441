<div class="toast-container">
  <div
    *ngFor="let message of toastService.messages"
    class="toast"
  >
    <message-panel
      *ngIf="message"
      [messages]="[message]"
    ></message-panel>
  </div>
</div>
