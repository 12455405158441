<div
  class="message-panel"
  *ngIf="messages?.length"
>
  <div
    class="message {{ message.type }}"
    [class.action]="message.action"
    attr.data-test="message_{{message.code}}"
    *ngFor="let message of messages"
    (click)="messageAction(message)"
  >
    <div class="message-row">
      <span
        class="message-content"
        [innerHtml]="message.code | translate: message?.parameters"
      ></span>
      <span
        *ngIf="message.action && message.actionText"
        class="message-action"
      >{{ message.actionText | translate }}
      </span>
    </div>
  </div>
</div>
