import { Component, Input, OnInit } from '@angular/core';
import { ReturnFormService } from 'src/app/service/return-form.service';

@Component({
  selector: 'operator-select',
  templateUrl: './operator-select.component.html',
  styleUrls: ['./operator-select.component.scss']
})
export class OperatorSelectComponent implements OnInit {

  @Input('name') operatorName!: string;
  @Input('delivery') deliveryType!: string;
  @Input('price') offerPrice!: string;
  @Input('excludedOperatorsForPostingCode') excludedOperatorsForPostingCode!: string[];

  @Input('errors') offerErrors!: any[] | undefined;
  @Input('enabled') isEnabled!: boolean;

  @Input('hasZoneFee') hasZoneFee!: boolean;

  savedPoint: string|undefined = undefined;
  isSelected: boolean = false;
  isRequired: boolean = true;

  constructor(
    private returnFormService: ReturnFormService
  ) { }

  ngOnInit(): void {
    this.returnFormService._operatorSelection$.subscribe(resp => {
      if (resp
          && resp.operator
          && resp.operator == this.operatorName
          && resp.deliveryType?.toString() == this.deliveryType
          && (!!resp.posId || this.excludedOperatorsForPostingCode.includes(this.operatorName))) {
        this.savedPoint = resp.posId;
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
    });
  }

}
