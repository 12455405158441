<div
  class="operator-select grid"
  [ngClass]="isEnabled ? (isSelected ? 'active' : 'enabled') : 'disabled'"
>
  <div
    class="row"
    attr.data-test="operator-select-{{operatorName}}"
    [ngClass]="!isEnabled && offerErrors ? 'unavailable' : ''"
  >
    <div
      class="operator-logo col-4 col-md-4"
      style="background-image: url('{{ operatorName | operatorLogoUrl }}');"
      >
    </div>

    <div
      class="tooltip"
      *ngIf="!isEnabled && offerErrors"
    >
      <span class="offer-errors">
        <span
          class="single-error"
          *ngFor="let error of offerErrors">
          {{ "ERROR_MESSAGES." + error | translate }}
        </span>
      </span>
    </div>

    <div class="operator-details col-12 col-md-12">
      <div class="operator-details-inner">
        <ng-content></ng-content>
        <div class="operator-data">
          <div class="column">
            <span
              class="operator-name"
            >{{ "OPERATORS." + operatorName | uppercase | translate }}</span>
            <span class="label operator-delivery-type">
              {{ deliveryType + "_INFO" | translate }}
              <span
                *ngIf="(!savedPoint && isEnabled && !excludedOperatorsForPostingCode.includes(operatorName))"
                class="link"
              >{{ 'FORM.RETURN_PARCEL.CHOOSE_POINT' | translate }}
              </span>
            </span>
          </div>

          <span
            *ngIf="!!offerPrice"
            class="operator-price"
          >{{ offerPrice | number:'1.2-2' }} zł<span *ngIf="hasZoneFee">*</span></span>
          <span
            class="unavailable"
            *ngIf="!isEnabled && offerErrors"
          >{{ "FORM.NO_OFFER" | translate }}
          </span>
        </div>
        <div
          class="operator-point"
          [ngClass]="savedPoint ? '' : 'hidden'"
        >
          <span
            data-test="operator-select-saved-point"
            *ngIf="savedPoint"
          >
            <span data-test="operator-select-selected-point">{{ 'FORM.RETURN_PARCEL.SELECTED_POINT' | translate }}: {{ savedPoint }}</span>
            <span class="link">{{ 'FORM.RETURN_PARCEL.CHANGE_POINT' | translate }}</span>
          </span>
        </div>
      </div>
    </div>

  </div>
</div>
