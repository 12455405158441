import { Injectable } from '@angular/core';
import { Message, MessageType } from '../../shared/model/message';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  messages: Message[] = [];

  showToast(
    code: string,
    type: MessageType = MessageType.INFO
  ) {
    this.messages.push({ type, code } as Message);

    setTimeout(() => {
      this.messages.shift();
    }, 40000);
  }
}
